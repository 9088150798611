<template>
  <v-container fluid>
    <v-card class="pa-5 mt-3">
      <v-data-table
        :headers="headers"
        :items="groups"
        :search="search"
        :items-per-page="-1"
        hide-default-footer
        :loading="loading"
        :loading-text="$t('loading')"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search')"
              outlined
              dense
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" persistent max-width="600px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ $t("add") }}
                  <v-icon> mdi-plus </v-icon>
                </v-btn>
              </template>
              <v-form ref="form" v-model="valid" >
                <v-card>
                  <v-toolbar color="primary darken-1" dark>
                    <v-card-title>
                      <span class="">{{
                        (editedIndex === -1 ? $t("add") : $t("edit")) +
                        " " +
                        $t("groups.group")
                      }}</span>
                    </v-card-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-text-field
                            outlined
                            hide-details
                            persistent-hint
                            v-model="editedItem.name"
                            :rules="[rulesWithLength.required]"
                            :label="$t('groups.groupName')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="py-0">
                          <v-card
                            outlined
                            style="overflow-y: auto"
                            height="400"
                          >
                            <v-card-text>
                              <v-treeview
                                selectable
                                :items="roles"
                                :filter="filterRoles"
                                :search="searchRole"
                                :label="$t('groups.roles')"
                                :open-all="true"
                                v-model="groupRoles"
                                item-key="id"
                                item-text="name"
                                selected-color="red"
                              >
                                <template v-slot:label="{ item }">
                                  <div class="v-treeview-node__label">
                                    {{ $t("roles." + item.name) }}
                                  </div>
                                </template>
                              </v-treeview>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      class="white--text"
                      @click="save"
                      :disabled="!valid"
                      :min-width="100"
                    >
                      <v-icon>mdi-content-save-outline</v-icon>
                      {{ $t("save") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="close">
                      {{ $t("cancel") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </v-dialog>
            <confirm-dialog
              :openDialog="dialogDelete"
              :onClicked="deleteItemConfirm"
              :onClose="closeDelete"
            ></confirm-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="text-end">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="editItem(item)" v-bind="attrs" v-on="on">
                  <v-icon> mdi-pencil </v-icon>
                </v-btn>
              </template>
              <span> {{ $t("edit") }} </span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="red darken-2"
                  icon
                  @click="deleteItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-delete </v-icon>
                </v-btn>
              </template>
              <span> {{ $t("delete") }}</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import ConfirmDialog from "../../components/ConfirmDialog.vue";
export default {
  components: { ConfirmDialog },
  data() {
    return {
      valid: true,
      loading: true,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      search: "",
      searchRole: "",
      headers: [
        { text: this.$t("groups.groupName"), value: "name" },
        { text: "", value: "actions" },
      ],
      groups: [],
      roles: [],
      editedItem: {
        name: "",
        description: "",
        groupRoles: [],
      },
      groupRoles: [],

      defaultItem: {
        name: "",
        description: "",
        groupRoles: [],
      },
      rules: [(value) => !!value || this.$t("ThisFieldIsRequired")],
      rulesWithLength: {
        required: (value) =>
          (value != null && value.length < 255) ||
          this.$t("ThisFieldIsRequired"),
        length: (value) => value.length < 255 || this.$t("ThisFieldIsRequired"),
      },
    };
  },
  created() {
    if (!this.$store.getters.isInRole(24)) {
      this.$router.push({ name: "notauthorize" });
    }

    this.loading = true;
    this.refreshTable();

    this.$axios.get("auth/GetRoles", {noToast: true}).then((response) => {
      this.roles = response.data.data;
    });
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  computed: {
    likesAllFruit() {
      return this.groupRoles.length === this.roles.length;
    },
    likesSomeFruit() {
      return this.groupRoles.length > 0 && !this.likesAllFruit;
    },
    icon() {
      if (this.likesAllFruit) return "mdi-close-box";
      if (this.likesSomeFruit) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    filterdRoles() {
      var roles = Object.assign([], this.roles);
      if (this.searchRole) {
        return roles.filter((item) => {
          return this.searchRole
            .toLowerCase()
            .split(" ")
            .every((v) => item.roleCaption.toLowerCase().includes(v));
        });
      } else {
        return roles;
      }
    },
    filterRoles() {
      return (item, search, textKey) => {
        return item[textKey].indexOf(search) > -1;
      };
    },
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllFruit == true) {
          this.groupRoles = [];
        } else {
          this.groupRoles = this.roles.map((obj) => obj.id);
        }
      });
    },
    editItem(item) {
      this.editedIndex = this.groups.indexOf(item);
      this.editedItem = Object.assign({}, item);

      var roles = [];
      if (item.group_roles) {
        for (var i = 0; i < item.group_roles.length; i++) {
          roles.push(item.group_roles[i].roles_id);
        }
      }

      this.groupRoles = roles;
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.groups.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      var deleteItem = this.groups[this.editedIndex];

      this.$axios
        .delete("DeleteGroup/" + deleteItem.id)
        .finally(() => {
          this.refreshTable();
        });

      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.groupRoles = [];
      });
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    refreshTable() {
      this.$axios
        .get("GetGroups", {noToast: true, failureToast: true})
        .then((response) => {
          this.groups = response.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    save() {
      var val = this.$refs.form.validate();
      if (val) {
        if (this.groupRoles.length == 0) {
          this.$toast.warning(this.$t("error.PleaseSelectAtLeastOneRole"));
          return;
        }

        if (this.editedIndex > -1) {
          var updatedItem = this.groups[this.editedIndex];
          updatedItem.name = this.editedItem.name;
          updatedItem.groupRoles = this.groupRoles;
          this.$axios
            .post("UpdateGroup/" + updatedItem.id, updatedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.close();
              }
            })
            .finally(() => {
              this.loading = false;
              this.refreshTable();
            });
        } else {
          this.editedItem.groupRoles = this.groupRoles;
          this.$axios
            .post("AddGroup", this.editedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.close();
              }
            })
            .finally(() => {
              this.refreshTable();
            });
        }
      }
    },
  },
};
</script>
